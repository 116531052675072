<template>
  <v-app >
    <router-view />
    <ScrollTopButton/>
  </v-app>
</template>

<script>
import ScrollTopButton from '@/components/LandingPage/ScrollTopButton.vue'

export default {
  components: {
    ScrollTopButton
  },
  name: 'App',
 
  
};
</script>

<style>


</style>
