<template>
<button v-if="isVisible" @click="scrollToTop" class="scroll-to-top">
    ↑
</button>
</template>

  
<script>
export default {
    data() {
        return {
            isVisible: false,
        };
    },
    mounted() {
        window.addEventListener('scroll', this.checkScrollPosition);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.checkScrollPosition);
    },
    methods: {
        checkScrollPosition() {
            this.isVisible = window.scrollY > 200;
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
    },
};
</script>

  
<style>
.scroll-to-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    background: linear-gradient(45deg, #646464, #1E1E1E);
    color: white;
    border: none;
    padding: 12px 18px;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
    font-size: 18px;
}

.scroll-to-top:hover {
    background: linear-gradient(45deg, #1E1E1E, #646464);
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.scroll-to-top:active {
    transform: translateY(0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
</style>
