
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  { 
    path: '/',
    name: 'Home',
    component: () => import('@/Views/HomeView.vue')
  },
  { 
    path: '/privacy',
    name: 'PrivacyPolicy',
    component: () => import('@/components/LandingPage/PrivacyPolicy.vue')
  },
  { 
    path: '/Terms-and-Conditions',
    name: 'TermsAndConditions',
    component: () => import('@/components/LandingPage/TermsConditions.vue')
  },
];


const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
